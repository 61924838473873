import React from 'react';

import Layout from '../layouts';

const testTemplate = ({ pageContext }) => {
  console.log('pageContext = ', pageContext);
  return (
    <Layout>
      <h1>Test</h1>
    </Layout>
  );
};

export default testTemplate;
